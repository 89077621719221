import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaPhone } from 'react-icons/fa';
import { supabase } from '@/lib/supabase';

const RegisterClient = ({ onRegisterSuccess, jobData }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const { name, email, phone, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError('Parolele nu se potrivesc.');
      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            name,
            phone,
            role: 'client'
          }
        }
      });

      if (error) throw error;

      if (data.user) {
        const { error: profileError } = await supabase
          .from('profiles')
          .upsert([
            {
              id: data.user.id,
              name,
              email,
              phone,
              role: 'client'
            }
          ], { onConflict: 'id' });

        if (profileError) throw profileError;

        onRegisterSuccess({ ...data.user, role: 'client' });
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.message);
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="w-full bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl overflow-hidden shadow-2xl">
      {/* Progress Bar */}
      <div className="w-full bg-gray-800/50 h-1.5">
        <div 
          className="bg-gradient-to-r from-gray-600 to-gray-500 h-1.5 transition-all duration-500 rounded-r-full"
          style={{ width: "100%" }}
        />
      </div>

      <div className="flex flex-col px-6 py-6">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-xl font-semibold text-white mb-2 text-center">
            Creează cont pentru a continua
          </h3>
          <p className="text-sm text-gray-400 mb-6 text-center">
            Completează datele tale pentru a finaliza cererea
          </p>

          <form onSubmit={handleSubmit} className="w-full space-y-4">
            <InputField
              icon={<FaUser className="text-gray-400" />}
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Nume complet"
              required
              className="w-full px-4 py-2 rounded-lg bg-gray-800/50 border border-gray-700 
                text-white placeholder-gray-500 focus:ring-gray-600 focus:border-gray-600
                transition-all duration-200"
            />
            <InputField
              icon={<FaEnvelope className="text-gray-400" />}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Adresa de email"
              required
              className="w-full px-4 py-2 rounded-lg bg-gray-800/50 border border-gray-700 
                text-white placeholder-gray-500 focus:ring-gray-600 focus:border-gray-600
                transition-all duration-200"
            />
            <InputField
              icon={<FaPhone className="text-gray-400" />}
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Număr de telefon"
              required
              className="w-full px-4 py-2 rounded-lg bg-gray-800/50 border border-gray-700 
                text-white placeholder-gray-500 focus:ring-gray-600 focus:border-gray-600
                transition-all duration-200"
            />
            <PasswordField
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Parolă"
              showPassword={showPassword}
              toggleVisibility={() => togglePasswordVisibility('password')}
              className="w-full px-4 py-2 rounded-lg bg-gray-800/50 border border-gray-700 
                text-white placeholder-gray-500 focus:ring-gray-600 focus:border-gray-600
                transition-all duration-200"
            />
            <PasswordField
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirmă parola"
              showPassword={showConfirmPassword}
              toggleVisibility={() => togglePasswordVisibility('confirmPassword')}
              className="w-full px-4 py-2 rounded-lg bg-gray-800/50 border border-gray-700 
                text-white placeholder-gray-500 focus:ring-gray-600 focus:border-gray-600
                transition-all duration-200"
            />

            {error && (
              <div className="bg-red-500/10 border border-red-500/20 px-4 py-2 rounded-lg">
                <p className="text-sm text-red-400">{error}</p>
              </div>
            )}

            <div className="px-6 py-4 border-t border-gray-700/50 bg-gray-800/30 -mx-6">
              <motion.button
                type="submit"
                className="w-full px-4 py-2 text-sm font-medium bg-gray-700 hover:bg-gray-600 
                  text-white rounded-lg transition-all duration-200 shadow-md 
                  flex items-center justify-center"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Finalizare Înregistrare
              </motion.button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ icon, className, ...props }) => (
  <div className="relative group">
    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 
      group-hover:text-gray-400 transition-colors duration-200">
      {icon}
    </span>
    <input
      {...props}
      className={`${className} hover:border-gray-600 focus:border-gray-500`}
      style={{ paddingLeft: "2.5rem" }}
    />
  </div>
);

const PasswordField = ({ showPassword, toggleVisibility, className, ...props }) => (
  <div className="relative group">
    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 
      group-hover:text-gray-400 transition-colors duration-200">
      <FaLock />
    </span>
    <input
      {...props}
      type={showPassword ? "text" : "password"}
      className={`${className} hover:border-gray-600 focus:border-gray-500`}
      style={{ paddingLeft: "2.5rem" }}
    />
    <button
      type="button"
      onClick={toggleVisibility}
      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 
        hover:text-gray-400 focus:outline-none transition-colors duration-200"
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
  </div>
);

export default RegisterClient;
