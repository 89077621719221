"use client";

import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { motion, AnimatePresence } from 'framer-motion';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuth } from '@/context/AuthContext';
import { supabase } from '@/lib/supabase';
import { useRouter } from 'next/navigation';
import { serviceCategories, tradesMapping } from '@/data/serviceCategories';
import RegisterClient from '@/components/hero/steps/registerClient';

import CityAutocomplete from './CityAutocomplete';
import { cities } from '@/data/cities';

const ServiceRequestForm = ({ cityFromUrl, category, relevantTrades }) => {
  const { user } = useAuth();
  const router = useRouter();
  const pathname = usePathname();
  const [currentStep, setCurrentStep] = useState(0);
  const [showRegister, setShowRegister] = useState(false);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [error, setError] = useState('');

  // Inițializăm formData cu valori default
  const [formData, setFormData] = useState({
    category: category,
    subcategory: '',
    city: cityFromUrl || '',
    description: '',
    startDate: '',
    budget: ''
  });

  // Definim isOnCityPage aici
  const pathParts = pathname.split('/').filter(Boolean);
  const isOnCityPage = pathParts.length === 4 && pathParts[0] === 'servicii';

  // Definim pașii formularului
  const steps = [
    {
      id: 'service',
      question: "Ce tip de serviciu aveți nevoie?",
      description: "Selectați categoria și tipul specific de serviciu",
      type: "service-select",
      required: true
    },
    {
      id: 'description',
      question: "Descrieți proiectul în detaliu",
      description: "Includeți cât mai multe detalii despre ce trebuie făcut",
      type: "textarea",
      placeholder: "Descrieți detaliat lucrarea...",
      required: true
    },
    {
      id: 'startDate',
      question: "Când doriți să înceapă lucrarea?",
      description: "Selectați perioada aproximativă de început",
      type: "select",
      options: [
        { value: 'urgent', label: 'Urgent - Cât mai curând posibil' },
        { value: '2days', label: 'În următoarele 2 zile' },
        { value: '1week', label: 'În următoarea săptămână' },
        { value: '2weeks', label: 'În următoarele 2 săptămâni' },
        { value: 'flexible', label: 'Sunt flexibil cu perioada' }
      ],
      required: true
    },
    {
      id: 'budget',
      question: "Care este bugetul estimat?",
      description: "Selectați un interval de buget aproximativ",
      type: "radio",
      options: [
        { value: 'under1000', label: 'Sub 1.000 RON' },
        { value: '1000-3000', label: '1.000 - 3.000 RON' },
        { value: '3000-5000', label: '3.000 - 5.000 RON' },
        { value: '5000-10000', label: '5.000 - 10.000 RON' },
        { value: 'over10000', label: 'Peste 10.000 RON' }
      ],
      required: true
    }
  ];

  // Funcție pentru a gsi orașul corect din lista noastră
  const getNormalizedCity = (urlCity) => {
    if (!urlCity) return '';
    
    // Convertim slug-ul în format normal
    return cities.find(city => 
      city.toLowerCase().replace(/\s+/g, '-') === urlCity.toLowerCase()
    ) || '';
  };

  // Pre-completează serviciul bazat pe URL
  useEffect(() => {
    if (pathParts.includes('servicii')) {
      const categoryId = pathParts[1];
      const subcategoryId = pathParts[2];
      
      setFormData(prev => ({
        ...prev,
        category: categoryId || '',
        subcategory: subcategoryId || '',
        city: cityFromUrl || prev.city || '' // Păstrăm valoarea existentă dacă nu avem cityFromUrl
      }));
    }
  }, [pathname, cityFromUrl]);

  useEffect(() => {
    const normalizedCity = getNormalizedCity(cityFromUrl);
    if (normalizedCity) {
      handleAnswer(normalizedCity);
    }
  }, [cityFromUrl]);

  const handleAnswer = (answer, field = steps[currentStep].id) => {
    setFormData(prev => ({
      ...prev,
      [field]: answer
    }));
  };

  const goToNextStep = () => {
    setError('');

    // Validare pentru fiecare pas
    switch (currentStep) {
      case 0: // Serviciu și oraș
        if (!formData.category || !formData.subcategory || !formData.city) {
          setError('Te rugăm să completezi toate câmpurile');
          return;
        }
        break;

      case 1: // Descriere
        if (!formData.description?.trim()) {
          setError('Te rugăm să adaugi o descriere a proiectului');
          return;
        }
        break;

      case 2: // Data start
        if (!formData.startDate) {
          setError('Te rugăm să selectezi perioada de început');
          return;
        }
        break;

      case 3: // Buget
        if (!formData.budget) {
          setError('Te rugăm să selectezi bugetul estimat');
          return;
        }
        handleSubmit();
        return;
    }

    // Trecem la următorul pas doar dacă nu suntem la ultimul pas
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setError(''); // Ștergem orice eroare când mergem înapoi
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser()
      
      if (!user) {
        // Salvăm datele în localStorage și arătăm formularul de înregistrare
        localStorage.setItem('pendingJobData', JSON.stringify(formData));
        setShowRegister(true);
        return;
      }

      // Convertim bugetul în număr
      const getBudgetValue = (budgetRange) => {
        switch(budgetRange) {
          case 'under1000': return 1000;
          case '1000-3000': return 3000;
          case '3000-5000': return 5000;
          case '5000-10000': return 10000;
          case 'over10000': return 15000;
          default: return 0;
        }
      };

      // Construim titlul din categoria și subcategoria selectată
      const selectedCategory = serviceCategories.find(cat => cat.id === formData.category);
      const selectedSubcategory = selectedCategory?.subcategories.find(sub => sub.id === formData.subcategory);
      
      // Găsim trade-ul potrivit din tradesMapping
      const trade = Object.entries(tradesMapping)
        .find(([_, [category, subcategories]]) => 
          category === formData.category && 
          subcategories.includes(formData.subcategory)
        )?.[0];

      const jobData = {
        title: `${selectedCategory?.name} - ${selectedSubcategory?.name}`,
        description: formData.description,
        budget: getBudgetValue(formData.budget),
        address: formData.city,
        client_id: user.id,
        tradeType: trade || formData.category, // Folosim trade-ul găsit sau categoria ca fallback
        jobType: formData.subcategory,
        urgency: formData.startDate === 'urgent' ? 'high' : 
                 formData.startDate === '2days' ? 'medium' : 'low',
        status: 'open',
        created_at: new Date().toISOString()
      }

      const { data, error } = await supabase
        .from('jobs')
        .insert([jobData])
        .select()

      if (error) throw error

      // Redirect sau reset form după succes
      router.push('/dashboard/client');
      
    } catch (error) {
      console.error('Error:', error)
      setError('A apărut o eroare la salvarea lucrării')
    }
  }

  const handleRegisterSuccess = async (userData) => {
    try {
      if (!userData) {
        throw new Error('Nu s-au primit datele utilizatorului');
      }

      const savedData = localStorage.getItem('pendingJobData');
      if (savedData) {
        try {
          const parsedData = JSON.parse(savedData);
          
          // Găsim trade-ul potrivit din tradesMapping
          const trade = Object.entries(tradesMapping)
            .find(([_, [category, subcategories]]) => 
              category === parsedData.category && 
              subcategories.includes(parsedData.subcategory)
            )?.[0];

          // Construim datele pentru job
          const dataToInsert = {
            title: `${serviceCategories.find(cat => cat.id === parsedData.category)?.name} - ${
              serviceCategories
                .find(cat => cat.id === parsedData.category)
                ?.subcategories.find(sub => sub.id === parsedData.subcategory)?.name
            }`,
            description: parsedData.description,
            budget: parsedData.budget === 'under1000' ? 1000 :
                    parsedData.budget === '1000-3000' ? 3000 :
                    parsedData.budget === '3000-5000' ? 5000 :
                    parsedData.budget === '5000-10000' ? 10000 : 15000,
            status: 'open',
            created_at: new Date().toISOString(),
            address: parsedData.city,
            tradeType: trade || parsedData.category, // Folosim trade sau categoria ca fallback
            jobType: parsedData.subcategory,
            urgency: parsedData.startDate === 'urgent' ? 'high' : 
                     parsedData.startDate === '2days' ? 'medium' : 'low',
            client_id: userData.id
          };

          const { error: supabaseError } = await supabase
            .from('jobs')
            .insert([dataToInsert])
            .select();

          if (supabaseError) throw supabaseError;
          
          // Ștergem datele din localStorage
          localStorage.removeItem('pendingJobData');
          
          // Redirecționăm către dashboard
          router.push('/dashboard/client');
        } catch (err) {
          console.error('Error creating job:', err);
          throw new Error('A apărut o eroare la salvarea jobului');
        }
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message || 'A apărut o eroare la înregistrare. Te rugăm să încerci din nou.');
    }
  };

  const renderStep = () => {
    console.log('Current Step:', currentStep);
    console.log('Form Data:', formData);

    switch (currentStep) {
      case 0:
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Categorie
              </label>
              <select
                value={formData.category || ''}
                onChange={(e) => handleAnswer(e.target.value, 'category')}
                className="w-full px-4 py-3  bg-white/10 border border-white/20 rounded-lg
            text-white placeholder-gray-400 focus:ring-2 focus:ring-yellow-400 focus:border-transparent
            transition-colors"
                  
              >
                <option value="">Selectează categoria</option>
                {serviceCategories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Service Type (Subcategory) */}
            <div>
              <label className="block text-sm font-medium text-white mb-2">
                Tip serviciu
              </label>
              <select
                value={formData.subcategory || ''}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, subcategory: e.target.value }));
                  console.log('Selected Subcategory:', e.target.value);
                }}
                className="w-full px-1  bg-white/10 border border-white/20 rounded-lg
            text-white placeholder-gray-400 focus:ring-2 focus:ring-yellow-400 focus:border-transparent
            transition-colors"
                disabled={!formData.category}
              >
                <option value="">Selectează tipul de serviciu</option>
                {formData.category && serviceCategories
                  .find(cat => cat.id === formData.category)
                  ?.subcategories.map(sub => (
                    <option key={sub.id} value={sub.id}>
                      {sub.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* City Selection */}
            {isOnCityPage ? (
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  Oraș
                </label>
                <input
                  type="text"
                  value={formData.city || ''}
                  disabled
                  className="w-full px-4 py-2 rounded-md bg-black-100 border border-black-300 
                    text-black-900 placeholder-black-500 cursor-not-allowed"
                />
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium text-white mb-2">
                  Oraș
                </label>
                <CityAutocomplete
                  initialValue={formData.city || ''}
                  onSelect={(city) => {
                    setFormData(prev => ({ ...prev, city }));
                    console.log('Selected City:', city);
                  }}
                />
              </div>
            )}
          </div>
        );

      case 1:
        return (
          <div className="space-y-4">
            <textarea
              value={formData.description || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              placeholder="Descrieți detaliat lucrarea..."
              className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg
                text-white placeholder-gray-400 focus:ring-2 focus:ring-yellow-400 focus:border-transparent
                transition-colors min-h-[150px] resize-none"
            />
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <select
              value={formData.startDate || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
              className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg
                text-white placeholder-gray-400 focus:ring-2 focus:ring-yellow-400 focus:border-transparent
                transition-colors"
            >
              <option value="">Selectează perioada</option>
              <option value="urgent">Urgent - Cât mai curând posibil</option>
              <option value="2days">În următoarele 2 zile</option>
              <option value="1week">În următoarea săptămână</option>
              <option value="2weeks">În următoarele 2 săptămâni</option>
              <option value="flexible">Sunt flexibil cu perioada</option>
            </select>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {steps[3].options.map((option) => (
                <button
                  key={option.value}
                  onClick={() => setFormData(prev => ({ ...prev, budget: option.value }))}
                  className={`p-4 rounded-lg border transition-colors text-left
                    ${formData.budget === option.value 
                      ? 'bg-yellow-400/20 border-yellow-400 text-white' 
                      : 'bg-white/10 border-white/20 text-gray-300 hover:bg-white/20'}`}
                >
                  <span className="font-medium">{option.label}</span>
                </button>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  if (showRegister) {
    return (
      <div className="w-full h-full">
        <RegisterClient 
          onRegisterSuccess={handleRegisterSuccess} 
          formData={formData} 
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="bg-white/10 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 shadow-xl">
        {/* Progress bar */}
        <div className="relative h-1">
          <div className="absolute inset-0 bg-gray-700/50" />
          <motion.div
            className="absolute left-0 top-0 h-full bg-yellow-400"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
            initial={{ width: 0 }}
            animate={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
            transition={{ duration: 0.3 }}
          />
        </div>

        {/* Conținutul formularului */}
        <div className="px-3 py-2">
          <div className="min-h-[480px] flex flex-col">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentStep}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="flex-1 flex flex-col"
              >
                {/* Header */}
                <div className="mb-8">
                  <h3 className="text-2xl font-semibold text-white mb-2">
                    {steps[currentStep].question}
                  </h3>
                  <p className="text-gray-400">
                    {steps[currentStep].description}
                  </p>
                </div>

                {/* Content area cu înălțime fixă */}
                <div className="flex-1 min-h-[280px]">
                  {renderStep()}
                </div>

                {/* Error message */}
                <AnimatePresence>
                  {error && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="bg-red-500/10 border border-red-500/20 px-4 py-3 rounded-lg mt-4"
                    >
                      <p className="text-sm text-red-400">{error}</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>

            {/* Navigation buttons - always at the bottom */}
            <div className="flex justify-between items-center mt-8 pt-4 border-t border-gray-800">
              {currentStep > 0 && (
                <button
                  onClick={goToPreviousStep}
                  className="flex items-center px-4 py-2 text-gray-400 hover:text-white transition-colors"
                >
                  <ChevronLeft className="w-5 h-5 mr-1" />
                  Înapoi
                </button>
              )}

              <button
                onClick={goToNextStep}
                className={`flex items-center px-6 py-3 bg-yellow-400 hover:bg-yellow-500 
                  text-gray-900 rounded-lg font-medium transition-all duration-200
                  ${currentStep === 0 ? 'ml-auto' : ''}`}
              >
                {currentStep === steps.length - 1 ? 'Finalizează' : 'Continuă'}
                {currentStep !== steps.length - 1 && <ChevronRight className="w-5 h-5 ml-1" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestForm;
