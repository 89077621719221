'use client'

import { FaCheckCircle, FaUsers, FaStar, FaMapMarkerAlt } from 'react-icons/fa';

export default function ServiceStats() {
  const stats = [
    { 
      number: '10,000+', 
      label: 'Proiecte Finalizate', 
      icon: FaCheckCircle,
      color: 'yellow-400',
    },
    { 
      number: '5,000+', 
      label: 'Profesioniști Verificați', 
      icon: FaUsers,
      color: 'yellow-400',
    },
    { 
      number: '4.8/5', 
      label: 'Rating Mediu', 
      icon: FaStar,
      color: 'yellow-400',
    },
    { 
      number: '50+', 
      label: 'Orașe Acoperite', 
      icon: FaMapMarkerAlt,
      color: 'yellow-400',
    }
  ];

  return (
    <div className="w-full mt-12 lg:mt-2">
      <div className="grid grid-cols-2  gap-6">
        {stats.map((stat, index) => (
          <div 
            key={index} 
            className="w-full bg-black bg-opacity-30 backdrop-blur-sm rounded-xl p-3 text-white 
                     hover:bg-opacity-40 transition-all duration-300 
                     transform hover:scale-105 hover:shadow-lg"
          >
            <div className="flex items-center space-x-4 mb-3">
              <stat.icon className={`text-${stat.color} text-1xl lg:text-3xl`} />
              <span className={`text-2xl lg:text-2xl font-bold text-${stat.color}`}>
                {stat.number}
              </span>
            </div>
            <div className="text-sm lg:text-base text-gray-200 font-medium">
              {stat.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
