import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { CheckCircle } from 'lucide-react';

export default function ReviewModal({ job, onClose, onSubmitReview }) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [hover, setHover] = useState(null);

  const handleSubmit = () => {
    if (rating === 0) {
      alert('Te rugăm să selectezi un rating');
      return;
    }
    onSubmitReview(rating, comment);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-8 border w-full max-w-md shadow-xl rounded-xl bg-white">
        <div className="mb-6 text-center">
          <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
          <h3 className="text-2xl font-bold text-gray-800">Job Finalizat cu Succes!</h3>
          <p className="text-gray-600 mt-2">Te rugăm să lași un review pentru worker</p>
        </div>

        <div className="flex justify-center mb-6">
          {[...Array(5)].map((star, i) => {
            const ratingValue = i + 1;
            return (
              <FaStar
                key={i}
                className="cursor-pointer mx-1 transition-colors duration-200"
                size={32}
                color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
                onClick={() => setRating(ratingValue)}
              />
            );
          })}
        </div>

        <textarea
          className="w-full p-4 border rounded-lg mb-6 focus:ring-2 focus:ring-purple-500 focus:border-transparent"
          rows="4"
          placeholder="Descrie experiența ta cu acest worker..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition duration-200"
          >
            Anulează
          </button>
          <button
            onClick={handleSubmit}
            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition duration-200"
          >
            Trimite Review
          </button>
        </div>
      </div>
    </div>
  );
}
